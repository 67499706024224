.portfolioList{
    width: 100%;
    margin: auto;
    margin-top: 10rem;
}

.portfolioList_projects{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* gap: 5%; */
    margin: auto;
    flex-wrap: wrap;
    /* flex-direction: column; */
}

.recentProjects {
    color: var(--black);
    font-size: 2rem;
    font-weight: bold;
  }
  
  .recentPortfolioTitle{
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
  }
.titlesOfPortfolio{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10vh 0;
}

@media screen and (max-width: 480px){
  .portfolioList{
    margin-top: -5rem;
  }
}
@media screen and (max-width: 920px){
  .portfolioList{
    margin-bottom: 10vh;
  }
}