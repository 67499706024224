.services {
  padding: 0 3rem 0 3rem;
  display: flex;
  /* height: 30rem; */
  margin-top: 9rem;
  margin-bottom: 28rem;
  /* scroll view */
  padding-top: 3rem;
}
/* left side */
.awesome {
  display: flex;
  flex-direction: column;
  position: relative;
  /* gap: 20px; */
}

.awesome > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome > :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome > :nth-child(3) {
  color: var(--gray);
  font-size: 14px;
  /* margin-top: 1rem; */
}
.s-button {
  width: 8rem;
  height: 2rem;
  margin: 0;
  /* margin-top: 5px; */
  /* margin-top: 1rem; */
}

/* right sided */
.cards {
  position: relative;
}
.cards > * {
  position: absolute;
}

/* blur */
.s-blur2 {
  left: 14rem;
  top: 8rem;
}

.s-blur1 {
  top: 13rem;
  left: -18rem;
}


@media screen and (max-width: 920px) {

  .services {
    margin-top: 0;
    flex-direction: column;
    gap: 3rem;
    /* height: 28rem; */
    padding: 0;
    /* width: 100%; */
    margin-bottom: 12rem;
  }

  .cards {
    position: relative;
    display: flex; 
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap: 17rem;
    justify-content: center; 
    align-items: center;
    margin: auto;
  }
  .cards > * {
    position: static;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  .s-button {
    width: 8rem;
    height: 2rem;
    /* margin-top: 1rem; */
  }
}
@media screen and (max-width: 480px){
  .services{
    margin-bottom: -12rem;
  }
}